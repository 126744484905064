import { computed } from "vue"
import type { Subject } from "~/types/subject"
import { getColorScheme } from "~/utilities/colors"

export function useChapterCard(props: { chapter: Subject }) {
  const theme = inject("theme") as ComputedRef<string>
  const colorScheme = getColorScheme(
    props.chapter.colorTag ?? "",
    theme.value,
  )

  const buildChapterUrl = (href: string) => {
    const parts = href.split("/")
    return `/produkter/${parts[1].replace(/-(?!.*-)/, "/")}/${parts[2]}`
  }

  const imageType = computed(() => {
    if (!props.chapter.img.src) {
      return "no-image"
    }
    
    switch (props.chapter.chapterType) {
      case "Kun fargefyll":
        return props.chapter.img.src ? "backgroundImage" : "no-image"
      case "Fargefyll med illustrasjon (.png)":
        return "illustration"
      case "Heldekkende bilde":
        return "backgroundImage"
      default:
        return props.chapter.img.src ? "backgroundImage" : "no-image" // Default to backgroundImage if an image source is present, otherwise no-image
    }
  })

  const calculateCardImageStyle = computed(() => {
    if (imageType.value === "no-image") {
      return { backgroundImage: "none" }
    }

    const style: Record<string, string> = {
      backgroundImage: `url('${props.chapter.img.src}')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }

    if (hasFocalPoint(props.chapter.img)) {
      const { focalPointXPercent, focalPointYPercent } =
        calculateFocalPointPercentages(props.chapter.img)
      style.backgroundPosition = `${focalPointXPercent}% ${focalPointYPercent}%`
    } else {
      style.backgroundPosition = "center"
    }

    return style
  })

  const cardGradientClass = computed(() => {
    const classes = [
      imageType.value === "backgroundImage"
        ? colorScheme.gradientImage
        : colorScheme.gradient,
    ]

    if (imageType.value !== "backgroundImage") {
      classes.push(colorScheme.bg)
    }

    return classes
  })

  return {
    colorScheme,
    buildChapterUrl,
    calculateCardImageStyle,
    cardGradientClass,
    imageType,
  }
}

// Helper functions
function hasFocalPoint(img: Subject["img"]): img is Subject["img"] & {
  focalPointX: number
  focalPointY: number
  width: number
  height: number
} {
  return (
    typeof img.focalPointX === "number" &&
    typeof img.focalPointY === "number" &&
    typeof img.width === "number" &&
    typeof img.height === "number"
  )
}

function calculateFocalPointPercentages(
  img: Subject["img"] & {
    focalPointX: number
    focalPointY: number
    width: number
    height: number
  },
) {
  const focalPointXPercent = (img.focalPointX / img.width) * 100
  const focalPointYPercent = (img.focalPointY / img.height) * 100
  return { focalPointXPercent, focalPointYPercent }
}
